const { isDevelop, isQa, isRest } = require('../stages');

const { login } = require('./login');
const passwordRecovery = require('./passwordRecovery');
const registration = require('./registration');
const profile = require('./profile');
const shared = require('./shared');
const purchases = require('./purchases');
const settigns = require('./settings');

// ============================ utils ============================
const isPrimitive = val => Object(val) !== val;
const getPropertyName = (prefix, key) =>
    prefix ? `${prefix}${key.charAt(0).toUpperCase()}${key.slice(1)}` : key;

const flatMap = (map, prefix) =>
    Object.keys(map).reduce((acc, value) => {
        if (isPrimitive(map[value])) {
            acc[getPropertyName(prefix, value)] = map[value];

            return acc;
        }

        return {
            ...acc,
            ...flatMap(map[value], getPropertyName(prefix, value)),
        };
    }, {});

// ============================ config ============================
const TEST_ATTRIBUTE = 'data-test';
const ERROR_POSTFIX = '-error';
const MOBILE_POSTFIX = '-mobile';
const SELECTED_POSTFIX = '-selected';

const map = {
    accessRecovery: passwordRecovery.accessRecovery,
    groupAccount: profile.groupAccount,
    header: shared.header,
    login,
    notifications: shared.notifications,
    profileContactInfo: profile.contactInfo,
    profileDocuments: profile.documents,
    profilePets: profile.pets,
    profileTravelers: profile.travelers,
    profileUserInfo: profile.userInfo,
    profileVisa: profile.visas,
    purchaseCard: purchases.card,
    purchaseFilter: purchases.filter,
    registrationActivation: registration.activation,
    registrationFull: registration.full,
    registrationShort: registration.short,
    requestRecoveryCode: passwordRecovery.requestRecoveryCode,
    settingsChangePassword: settigns.changePassword,
    tabs: shared.tabs,
};

const ids =
    isDevelop || isQa || isRest
        ? Object.keys(map).reduce((acc, value) => {
              acc[value] = flatMap(map[value]);

              return acc;
          }, {})
        : {};

// ============================ export ============================
const formatValue = (val, { error, mobile, isSelected } = {}) => {
    let newValue = val;

    if (error) {
        newValue += ERROR_POSTFIX;
    }
    if (mobile) {
        newValue += MOBILE_POSTFIX;
    }
    if (isSelected) {
        newValue += SELECTED_POSTFIX;
    }

    return newValue;
};

const getTestIdGetter = dictKey => {
    const dictionary = ids[dictKey] || {};

    return (key, options) => {
        if (typeof key === 'undefined') {
            return dictionary;
        }

        const value = dictionary[key];

        if (!value) {
            return {};
        }

        return { [TEST_ATTRIBUTE]: formatValue(value, options) };
    };
};

const dictKeys = {
    ACCESS_RECOVERY: 'accessRecovery',
    GROUP_ACCOUNT: 'groupAccount',
    HEADER: 'header',
    LOGIN: 'login',
    NOTIFICATIONS: 'notifications',
    PROFILE_CONTACT_INFO: 'profileContactInfo',
    PROFILE_DOCUMENTS: 'profileDocuments',
    PROFILE_PETS: 'profilePets',
    PROFILE_TRAVELERS: 'profileTravelers',
    PROFILE_USER_INFO: 'profileUserInfo',
    PROFILE_VISA: 'profileVisa',
    PURCHASES_CARD: 'purchaseCard',
    PURCHASES_FILTER: 'purchaseFilter',
    REGISTRATION_ACTIVATION: 'registrationActivation',
    REGISTRATION_FULL: 'registrationFull',
    REGISTRATION_SHORT: 'registrationShort',
    REQUEST_RECOVERY_CODE: 'requestRecoveryCode',
    SETTINGS_CHANGE_PASSWORD: 'settingsChangePassword',
    TABS: 'tabs',
};

module.exports = {
    dictKeys,
    formatValue,
    getTestIdGetter,
    ids,
    TEST_ATTRIBUTE,
};
