const short = {
    alert: 'reg:step1:alert',
    alertText: 'reg:step1:alertText',
    childLink: 'reg:step1:child',
    code: 'reg:step1:smscode',
    content: 'reg:step1:content',
    email: 'reg:step2:email',
    form: 'reg:step1:form',
    password: 'reg:step1:pass',
    phone: 'reg:step1:tel',
    phoneField: 'reg:step1:phoneSelector',
    popupRegLink: 'reg:step1:goemail',
    requestCodeButton: 'reg:step1:sendsms',
    submitButton: 'reg:step1:submit',
    title: 'reg:step1:title',
};

const full = {
    agreement: 'reg:step2:agrmnt',
    alert: 'reg:step2:alert',
    birthdateError: 'reg:step2:birthdate-error',
    birthdayDate: 'reg:step2:birthdate',
    country: 'reg:step2:doc-country',
    docNumber: 'reg:step2:doc-num',
    docs: 'reg:step2:docs_field',
    docSeries: 'reg:step2:doc-series',
    docsField: 'reg:step2:docsSelector',
    docsList: 'reg:step2:docs_list',
    docType: 'reg:step2:doc-type',
    email: 'reg:step2:email',
    expireDate: 'reg:step2:doc-edate',
    hasCard: 'reg:step2:tempcard',
    homeAirport: 'reg:step2:city',
    issueDate: 'reg:step2:doc-sdate',
    latinName: 'reg:step2:fname-en',
    latinSurname: 'reg:step2:lname-en',
    masterPassword: 'reg:step2:pass2',
    masterPasswordEye: 'reg:step2:pass2-eye',
    memberId: 'reg:step2:tempcardnum',
    name: 'reg:step2:fname',
    password: 'reg:step2:pass',
    passwordEye: 'reg:step2:pass-eye',
    phone: 'reg:step2:phonefield',
    phoneField: 'reg:step2:phoneSelector',
    relativeMemberId: 'reg:step2:relative-member-id',
    section: {
        contact: 'reg:step2:section:contact',
        contactTitle: 'reg:step2:section:contact:title',
        document: 'reg:step2:section:contact',
        documentTitle: 'reg:step2:section:document:title',
        parents: 'reg:step2:section:parents',
        parentsTitle: 'reg:step2:section:parents:title',
        personal: 'reg:step2:section:personal',
        personalTitle: 'reg:step2:section:personal:title',
    },
    sexError: 'reg:step2:sex-error',
    sexF: 'reg:step2:sex_fem',
    sexM: 'reg:step2:sex_male',
    submit: 'reg:step2:submit',
    surname: 'reg:step2:lname',
    title: 'reg:step2:title',
};

const activation = {
    activationTitle: 'reg:activation:title',
    button: 'reg:activation:button',
    successTitle: 'reg:success:title',
};

module.exports = { activation, full, short };
