const changePassword = {
    block: 'settings:password:block',
    buttonSave: 'settings:password:button_save',
    fieldConfirm: 'settings:password:field_confirm',
    fieldNew: 'settings:password:field_new',
    fieldOld: 'settings:password:field_old',
    messageError: 'settings:password::error',
    messageSuccess: 'settings:password:success',
};

module.exports = { changePassword };
