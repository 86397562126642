const userInfo = {
    birthday: 'profile:pers_data:bdate',
    birthdayField: 'profile:pers_data:bdate_field',
    cancelButton: 'profile:pers_data:cancel',
    closeButton: 'profile:pers_data:close',
    editButton: 'profile:pers_data:edit_button',
    error: 'profile:pers_data:error',
    errorClose: 'profile:pers_data:error_close',
    firstNameEN: 'profile:pers_data:latname',
    firstNameFieldEN: 'profile:pers_data:latname_field',
    firstNameRU: 'profile:pers_data:kirname',
    form: 'profile:pers_data:edit_form',
    gender: 'profile:pers_data:gender',
    lastNameEN: 'profile:pers_data:surname_lat',
    lastNameFieldEN: 'profile:pers_data:lastame_lat_field',
    lastNameRU: 'profile:pers_data:surname',
    personalBlock: 'page:profile:personal',
    saveButton: 'profile:pers_data:save',
    sexError: 'profile:pers_data:gender-error',
    sexF: 'profile:pers_data:gender_female',
    sexM: 'profile:pers_data:gender_male',
    tooltip: 'profile:pers_data:tooltip',
    tooltipLink: 'profile:pers_data:tooltip_link',
};

const contactInfo = {
    block: 'profile:contacts:block',
    closeButton: 'profile:contacts:close',
    confirmedEmailLabel: 'profile:contacts:mail_conf',
    confirmedPhoneLabel: 'profile:contacts:phone_conf',
    confirmEmailButton: 'profile:contacts:email_submit',
    confirmPhoneButton: 'profile:contacts:tel_submit',
    editButton: 'profile:contacts:edit_button',
    email: 'profile:contacts:email',
    emailAlert: 'profile:contacts:email_edit_alert',
    emailCancelButton: 'profile:contacts:email_cancel',
    emailCode: 'profile:contacts:email_code',
    emailCodeButton: 'profile:contacts:email_code_button',
    emailEditButton: 'profile:contacts:email_edit_button',
    emailField: 'profile:contacts:email_field',
    emailFieldValidation: 'profile:contacts:email_field_error',
    emailNotification: 'profile:contacts:email_notification',
    emailPopup: 'profile:contacts:email_edit_popup',
    emailPopupCloseButton: 'profile:contacts:email_edit_popup_close_button',
    emailResendButton: 'profile:contacts:email_resend',
    notification: 'profile:contacts:notification',
    phone: 'profile:contacts:phone',
    phoneAlert: 'profile:contacts:phone_edit_alert',
    phoneCancelButton: 'profile:contacts:phone_cancel',
    phoneCode: 'profile:contacts:tel_code',
    phoneCodeButton: 'profile:contacts:phone_code_button',
    phoneEditButton: 'profile:contacts:phone_edit_button',
    phoneField: 'profile:contacts:phone_field',
    phoneFieldValidation: 'profile:contacts:phone_field_error',
    phoneNotification: 'profile:contacts:phone_notification',
    phonePopup: 'profile:contacts:phone_edit_popup',
    phonePopupCloseButton: 'profile:contacts:phone_edit_popup_close_button',
    phoneResendButton: 'profile:contacts:phone_resend',
    resendEmailButton: 'profile:contacts:email_resend',
    resendPhoneButton: 'profile:contacts:tel_resend',
    tabProfile: 'profile:title-selected',
};

const documentsCommon = {
    countryOfIssue: 'profile:docs:select_country',
    countryOfIssueList: 'profile:docs:list_country',
    countryOfIssueListMenu: 'profile:docs:select_country:select:menuList',
    dateOfIssue: 'profile:docs:date',
    expireDate: 'profile:docs:exp_date',
};

const documents = {
    addButton: 'profile:docs:add_button',
    addForm: 'profile:docs:add_form',
    archiveButton: 'profile:docs:archive',
    birthCertificate: {
        ...documentsCommon,
        number: 'profile:docs:add_form_number',
        series: 'profile:docs:add_form_serie',
        seriesNumber: 'profile:docs:seriesNumber',
    },
    cancelButton: 'profile:docs:add_form_cancel',
    container: 'profile:docs:docs',
    docType: 'profile:docs:select_docs',
    docTypeList: 'profile:docs:list_docs',
    docTypeListMenu: 'profile:docs:select_docs:select:menuList',
    editButton: 'profile:docs:edit_button',
    editForm: 'profile:docs:edit_form',
    listDocNumber: 'profile:docs:number',
    listDocType: 'profile:docs:type',
    listExpirationDate: 'profile:docs:expdate',
    passport: {
        ...documentsCommon,
        number: 'profile:docs:serienumber',
    },
    row: 'profile:docs:row',
    saveButton: 'profile:docs:add_form_save',
    title: 'profile:docs:title',
    tooltip: 'profile:docs:tooltip',
};

const visas = {
    addButton: 'profile:visa:add_button',
    addForm: 'profile:visa:add_form',
    archiveButton: 'profile:visa:arcive',
    cancelButton: 'profile:visa:cancel',
    container: 'profile:visa:visa',
    dateOfIssue: 'profile:visa:date_issue',
    editButton: 'profile:visa:edit_button',
    editForm: 'profile:visa:edit_form',
    expireDate: 'profile:visa:expiration',
    listDocNumber: 'profile:docs:number',
    listDocType: 'profile:docs:type',
    listExpirationDate: 'profile:docs:expdate',
    removeButton: 'profile:visa:delete_button',
    removeCancel: 'profile:visa:delete_ancel_button',
    removeConfirm: 'profile:visa:delete_confirm_button',
    removePopup: 'profile:visa:delete_confirm',
    removeSuccessPopup: 'profile:visa:delete_success',
    row: 'profile:visa:row',
    saveButton: 'profile:visa:save',
    title: 'profile:visa:title',
    visa: {
        birthCountry: 'profile:visa:birth_country',
        birthCountryList: 'profile:visa:birth_country_list',
        birthCountryListMenu: 'profile:visa:birth_country:select:menuList',
        countryOfIssue: 'profile:visa:country',
        dateOfIssue: 'profile:visa:date_issue',
        destinationCountry: 'profile:visa:entry_country',
        destinationCountryList: 'profile:visa:entry_country_list',
        destinationCountryListMenu:
            'profile:visa:entry_country:select:menuList',
        endDate: 'profile:visa:end_date',
        expireDate: 'profile:visa:end_date',
        listCountry: 'profile:visa:country_list',
        multi: 'profile:visa:shengen',
        number: 'profile:visa:number',
        placeOfBirth: 'profile:visa:place_birth',
        placeOfIssue: 'profile:visa:place_issue',
        startDate: 'profile:visa:start_date',
    },
};

const travelers = {
    birthday: 'profile:traveler:birthday',
    close: 'profile:traveler:close',
    document: 'profile:traveler:document',
    documents: 'profile:traveler:documents',
    edit: 'profile:traveler:edit',
    email: 'profile:traveler:email',
    empty: 'profile:traveler:empty',
    firstName: 'profile:traveler:first_name',
    form: 'profile:traveler:form',
    formCancel: 'profile:traveler:form:cancel',
    formContactsEmail: 'profile:traveler:form:contacts:email',
    formContactsPhone: 'profile:traveler:form:contacts:phone',
    formDocumentBirthCountry: 'profile:traveler:form:document:birth_country',
    formDocumentCountry: 'profile:traveler:form:document:country',
    formDocumentDateOfIssue: 'profile:traveler:form:document:date_of_issue',
    formDocumentDestination: 'profile:traveler:form:document:destination',
    formDocumentEmpty: 'profile:traveler:form:document:empty',
    formDocumentExpire: 'profile:traveler:form:document:expire',
    formDocumentIssued: 'profile:traveler:form:document:issued',
    formDocumentItem: 'profile:traveler:form:document:item',
    formDocumentNew: 'profile:traveler:form:document:new',
    formDocumentNumber: 'profile:traveler:form:document:number',
    formDocumentPlaceOfBirth: 'profile:traveler:form:document:place_of_birth',
    formDocumentPlaceOfIssue: 'profile:traveler:form:document:place_of_issue',
    formDocumentRemove: 'profile:traveler:form:document:remove',
    formDocumentSchengen: 'profile:traveler:form:document:schengen',
    formDocumentSeries: 'profile:traveler:form:document:series',
    formDocumentStart: 'profile:traveler:form:document:start',
    formDocumentType: 'profile:traveler:form:document:type',
    formLoyaltyAirline: 'profile:traveler:form:loyalty:airline',
    formLoyaltyHasCard: 'profile:traveler:form:loyalty:has_card',
    formLoyaltyNumber: 'profile:traveler:form:loyalty:number',
    formPersonalBirthday: 'profile:traveler:form:personal:birthday',
    formPersonalFirstName: 'profile:traveler:form:personal:first_name',
    formPersonalGender: 'profile:traveler:form:personal:gender',
    formPersonalGenderFemale: 'profile:traveler:form:personal:gender:female',
    formPersonalGenderMale: 'profile:traveler:form:personal:gender:male',
    formPersonalLastName: 'profile:traveler:form:personal:last_name',
    formSave: 'profile:traveler:form:save',
    initials: 'profile:traveler:initials',
    item: 'profile:traveler:item',
    lastName: 'profile:traveler:lastName',
    list: 'profile:traveler:list',
    loyalty: 'profile:traveler:loyalty',
    mobileBack: 'profile:traveler:mobile:back',
    mobileCancel: 'profile:traveler:mobile:cancel',
    mobileClose: 'profile:traveler:mobile:close',
    mobileContactsEdit: 'profile:traveler:mobile:contacts:edit',
    mobileContactsEmail: 'profile:traveler:mobile:contacts:email',
    mobileContactsEmpty: 'profile:traveler:mobile:contacts:empty',
    mobileContactsForm: 'profile:traveler:mobile:contacts:form',
    mobileContactsItem: 'profile:traveler:mobile:contacts:item',
    mobileContactsNew: 'profile:traveler:mobile:contacts:new',
    mobileContactsPhone: 'profile:traveler:mobile:contacts:phone',
    mobileContactsRemove: 'profile:traveler:mobile:contacts:remove',
    mobileContactsToggle: 'profile:traveler:mobile:contacts:toggle',
    mobileDocumentEdit: 'profile:traveler:mobile:document:edit',
    mobileDocumentEmpty: 'profile:traveler:mobile:document:empty',
    mobileDocumentExpire: 'profile:traveler:mobile:document:expire',
    mobileDocumentForm: 'profile:traveler:mobile:document:form',
    mobileDocumentItem: 'profile:traveler:mobile:document:item',
    mobileDocumentNew: 'profile:traveler:mobile:document:new',
    mobileDocumentNumber: 'profile:traveler:mobile:document:number',
    mobileDocumentRemove: 'profile:traveler:mobile:document:remove',
    mobileDocumentToggle: 'profile:traveler:mobile:document:toggle',
    mobileDocumentType: 'profile:traveler:mobile:document:type',
    mobileLoyaltyAirline: 'profile:traveler:mobile:loyalty:airline',
    mobileLoyaltyEdit: 'profile:traveler:mobile:loyalty:edit',
    mobileLoyaltyEmpty: 'profile:traveler:mobile:loyalty:empty',
    mobileLoyaltyForm: 'profile:traveler:mobile:loyalty:form',
    mobileLoyaltyItem: 'profile:traveler:mobile:loyalty:item',
    mobileLoyaltyNew: 'profile:traveler:mobile:loyalty:new',
    mobileLoyaltyNumber: 'profile:traveler:mobile:loyalty:number',
    mobileLoyaltyRemove: 'profile:traveler:mobile:loyalty:remove',
    mobileLoyaltyToggle: 'profile:traveler:mobile:loyalty:toggle',
    mobilePersonalBirthday: 'profile:traveler:mobile:personal:birthday',
    mobilePersonalEdit: 'profile:traveler:mobile:personal:edit',
    mobilePersonalFirstName: 'profile:traveler:mobile:personal:first_name',
    mobilePersonalForm: 'profile:traveler:mobile:personal:form',
    mobilePersonalGender: 'profile:traveler:mobile:personal:gender',
    mobilePersonalLastName: 'profile:traveler:mobile:personal:last_name',
    mobileRemove: 'profile:traveler:mobile:remove',
    mobileRemoveCancel: 'profile:traveler:mobile:remove_cancel',
    mobileRemoveConfirm: 'profile:traveler:mobile:remove_confirm',
    mobileSave: 'profile:traveler:mobile:save',
    more: 'profile:traveler:more',
    new: 'profile:traveler:new',
    phone: 'profile:traveler:phone',
    remove: 'profile:traveler:remove',
    removeCancel: 'profile:traveler:remove:cancel',
    removeConfirm: 'profile:traveler:remove:confirm',
};

const groupAccount = {
    block: 'profile:group_account:block',
    collapse: 'profile:group_account:collapse',
    deleteCancel: 'profile:group_account:delete:cancel',
    deleteConfirm: 'profile:group_account:delete:confirm',
    deleteOpen: 'profile:group_account:delete:open',
    infoBalance: 'profile:group_account:info:balance',
    infoIcon: 'profile:group_account:info:icon',
    infoLink: 'profile:group_account:info:link',
    infoTitle: 'profile:group_account:info:title',
    inviteCancel: 'profile:group_account:invite:cancel',
    inviteConfirm: 'profile:group_account:invite:confirm',
    inviteError: 'profile:group_account:invite:error',
    inviteModal: 'profile:group_account:invite:modal',
    inviteName: 'profile:group_account:invite:name',
    inviteNumber: 'profile:group_account:invite:number',
    inviteOpen: 'profile:group_account:invite:open',
    leaveCancel: 'profile:group_account:leave:cancel',
    leaveConfirm: 'profile:group_account:leave:confirm',
    leaveOpen: 'profile:group_account:leave:open',
    memberDate: 'profile:group_account:member:date',
    memberName: 'profile:group_account:member:name',
    memberStatus: 'profile:group_account:member:status',
    notification: 'profile:group_account:notification:statusMessage',
    notificationCancel:
        'profile:group_account:notification:statusMessage:cancel',
    notificationConfirm:
        'profile:group_account:notification:statusMessage:confirm',
    notificationName: 'profile:group_account:notification:name',
    notificationTitle: 'profile:group_account:notification:title',
    notificationToggle:
        'profile:group_account:notification:statusMessage:toggle',
    rules: 'profile:group_account:rules',
    unavailable: 'profile:group_account:unavailable',
};

const pets = {
    block: 'profile:pets:block',
    edit: 'profile:pets:edit',
    editAvatar: 'profile:pets:edit:avatar',
    editClose: 'profile:pets:edit:close',
    editNickname: 'profile:pets:edit:nickname',
    editNotification: 'profile:pets:edit:notification',
    editRemove: 'profile:pets:edit:remove',
    editSubmit: 'profile:pets:edit:submit',
    editType: 'profile:pets:edit:type',
    editTypeCustom: 'profile:pets:edit:type:custom',
    editWeight: 'profile:pets:edit:weight',
    empty: 'profile:pets:empty',
    item: 'profile:pets:item',
    itemAvatar: 'profile:pets:item:avatar',
    itemDelete: 'profile:pets:item:delete',
    itemDeleteCancel: 'profile:pets:item:delete:cancel',
    itemDeleteConfirm: 'profile:pets:item:delete:confirm',
    itemEdit: 'profile:pets:item:edit',
    itemIcon: 'profile:pets:item:icon',
    itemLink: 'profile:pets:item:link',
    itemNickname: 'profile:pets:item:nickname',
    itemType: 'profile:pets:item:type',
    itemWeight: 'profile:pets:item:weight',
    list: 'profile:pets:list',
    more: 'profile:pets:more',
    new: 'profile:pets:new',
    page: 'profile:pets:page',
    questions: 'profile:pets:questions',
    questionsChat: 'profile:pets:questions:chat',
    questionsTitle: 'profile:pets:questions:title',
    transportation: 'profile:pets:transportation',
    transportationInfo: 'profile:pets:transportation:info',
    transportationInfoClose: 'profile:pets:transportation:info:close',
    transportationInfoCondition: 'profile:pets:transportation:info:condition',
    transportationInfoConditions: 'profile:pets:transportation:info:conditions',
    transportationInfoImage: 'profile:pets:transportation:info:image',
    transportationInfoTitle: 'profile:pets:transportation:info:title',
    transportationItem: 'profile:pets:transportation:item',
    transportationItemButton: 'profile:pets:transportation:item:button',
    transportationItemSize: 'profile:pets:transportation:item:size',
    transportationItemText: 'profile:pets:transportation:item:text',
    transportationItemTitle: 'profile:pets:transportation:item:title',
};

module.exports = {
    contactInfo,
    documents,
    groupAccount,
    pets,
    travelers,
    userInfo,
    visas,
};
