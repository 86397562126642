const filter = {
    block: 'purchases:filter:block',
    button: 'purchases:filter:button',
    list: 'purchases:filter:list',
    listItem: 'purchases:filter:listItem',
};

const card = {
    block: 'purchases:card:block',
    date: 'purchases:card:date',
    id: 'purchases:card:id',
    price: 'purchases:card:price',
    status: 'purchases:card:status',
};

module.exports = { card, filter };
