const login = {
    alert: 'auth:form:alert',
    login: 'auth:form:login',
    password: 'auth:form:pass',
    passwordRecovery: 'auth:form:forgot',
    passwordRecoveryPopup: 'auth:pass_rec_popup',
    popup: 'auth:form:popup',
    registration: 'auth:form:reg',
    socialFB: 'auth:form:social:fb',
    socialGoogle: 'auth:form:social:google',
    socialTitle: 'auth:form:social:title',
    socialVK: 'auth:form:social:vk',
    submit: 'auth:form:submit',
    title: 'auth:form:title',
};

module.exports = {
    login,
};
