const requestRecoveryCode = {
    backToLogin: 'pass-rec:form:back-to-auth',
    code: 'pass-rec:form:code',
    infoMessage: 'pass-rec:form:desc',
    login: 'pass-rec:form:login',
    popup: 'pass-rec:form:popup',
    submit: 'pass-rec:form:request',
    submitNext: 'pass-rec:form:next',
    title: 'pass-rec:form:title',
};
const accessRecovery = {
    backToLogin: 'pass-rec:form:back-to-auth',
    infoMessage: 'pass-rec:form:desc',
    password: 'pass-rec:form:newpass',
    passwordConfirmation: 'pass-rec:form:newpass-conf',
    popup: 'pass-rec:form:popup',
    submit: 'pass-rec:form:save',
    title: 'pass-rec:form:title',
};

module.exports = {
    accessRecovery,
    requestRecoveryCode,
};
