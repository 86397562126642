const header = {
    absolute: 'global:header:absolute',
    avatar: 'global:header:usermenu',
    logout: 'auth:form:exit',
    userInfoName: 'myprofile:profileHeader:fname',
};

const tabs = {
    miles: 'miles:title',
    offers: 'offers:title',
    profile: 'profile:title',
    settings: 'settings:title',
    statistics: 'statistics:title',
    trips: 'trips:title',
};

const notifications = {
    notification: 'global:notification',
};

module.exports = { header, notifications, tabs };
